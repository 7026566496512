export const PRIVACY_POLICY = {
  title: 'Privacy Policy',
  info: `
  <h1 style="text-align: center;">Player’s Health Privacy Policy</h1>
  <p>
      <strong>Effective Date:</strong> December 31, 2020
  </p>
  <p>
      <h3>
          Introduction
      </h3>
  </p>
  <p>
      Player's Health's mission is to help youth athletes set and reach their goals by making sports a better, safer, and more secure place. We achieve our mission by offering sports organizations the best risk management solutions, comprehensive insurance coverage, policy options, faster claims experiences, and a variety of products and services to address their specific needs.
  </p>
  <p>
      To do this, we recognize you must trust us with your personal information. Maintaining your trust is one of Player’s Health’s top priorities, and as a result, we abide by the following principles to protect your privacy:
  </p>
  <ul>
      <li>
          We protect your personal information and use it only in connection with your use of our website and Service as set forth in our Terms of Use Agreement and described in this Privacy Policy.
      </li>
      <li>
          Because privacy and security go hand-in-hand, we have implemented industry standard administrative, technical and physical security safeguards designed to protect your and your employees’ information.
      </li>
  </ul>
  <p>
      This Privacy Policy applies to all the data, including previously-collected data, from visitors of playershealth.com as well as to users (“Users”) of the online services, mobile services, software, and other services that we provide or otherwise make available on playershealth.com (collectively, “the Service”).
  </p>
  <p>
      <strong>
          By using the Service, you agree to the terms of this Privacy Policy and our Terms of Use.
      </strong>
  </p>
  <p>
      <h3>
          Revisions to the Privacy Policy
      </h3>
  </p>
  <p>
      We may, in our sole discretion, modify or update this Privacy Policy from time to time, and will mark new versions with a corresponding effective date. If we make material changes to it, we will notify you through the Service, or by other means so that you may review the changes before you continue to use the Service. Your continued use of the Service after we publish or communicate a notice about any changes to this Privacy Policy means that you consent to the changes.
  </p>
  <p>
      <h3>
          1. INFORMATION COLLECTION
      </h3>
  </p>
  <p>
      The amount of information we collect depends on which features of the Service you use. Player’s Health needs personal information about your organization, your athletes, coaches and any stakeholder as applicable to provide the Service, and your providing the information to us constitutes your consent to our processing of this information. Additionally, because we provide a wide range of Risk Management and benefits management features, we may collect information about you from your sports organization, rather than directly from you. By submitting information to us, you represent and warrant that you have permission and the authority to do so and that any permission has been granted in accordance with applicable law.
  </p>
  <p>
      More specifically, we may collect the following categories of personal information about you:
  </p>
  <ul>
      <li>
          <strong>Registration Information:</strong> Your email address and password (your login credentials), and other information necessary to set up your account on the Service, which will depend on the features you use or your employer chooses to use.
      </li>
      <li>
          <strong>Identity information:</strong> Your name, physical address, Social Security Number/tax identification number, and other identifiers used to manage and track staff compliance processes and benefits.
      </li>
      <li>
          <strong>Demographic information:</strong> If you voluntarily submit information regarding your race in response to Equal Employment Opportunity Commission questionnaires to your employer through Player’s Health.
      </li>
      <li>
          <strong>Bank account information:</strong> Player’s Health needs bank account and routing numbers if you use the Service to provide insurance services, brokerage partner payments, telemedicine and clinic engagement and intake.
      </li>
      <li>
          <strong>Insurance information:</strong> If your sports organization manages or obtain insurances (General Liability, Participant Accident, D&O, Crime and Abuse & Molestation) through Player’s Health Cover, we may assist you with enrollment in insurance coverage, and Player’s Health Cover may process information related to your insurance coverage, including eligibility and enrollment information. We may also help you process or resolve claims for injuries, or incidents involving abuse.
      </li>
      <li>
          <strong>Background Check Information:</strong> If you use PH Clear to manage your background check process, we may work with a third party to facilitate staff background checks and collect this information for you.
      </li>
      <li>
          <strong>Information from Third-Party Sites and Services:</strong> If you use Player’s Health to manage third party services that integrate with the Service, we may have access to your information on such third-party services. We may, for example, have information about your use of a third-party registration service provider.
      </li>
      <li>
          <strong>Device and IP Information:</strong> When you access the Service, we automatically receive the internet protocol (“IP”) address of your computer or the proxy server that you use to access the internet, in addition to other technical information such as your computer operating system details, your type of web browser, your mobile device (including your mobile device identifier provided by your mobile device operating system), your mobile operating system, and the name of your ISP or mobile carrier. Player’s Health may log this information and analyze it to determine anomalous behavior, detect threats and generally improve the Service.
      </li>
      <li>
          <strong>From your interaction with Customer Support:</strong> When you contact our customer support team, we may review your Player’s Health user profile. Customer support will collect the information necessary to categorize your questions, respond to them, and, if applicable, investigate and resolve any issue you bring to our attention or that we discover independently. We may also use this information to track potential problems and trends, improve the Service, and customize our support responses to better serve you.
      </li>
      <li>
          <strong>Cookies, Beacons, Mobile SDKs, and similar technologies:</strong> We use cookies and similar technologies, including beacons in email messages, and application developer platforms that use mobile Software Development Kits ("SDKs") to collect information about your use of any mobile application that we make available to you, and other information related to how browsers and mobile devices interact with the Service. These technologies help us recognize your browser or mobile device when you log in to our website and use our mobile applications and help us improve your experience with the Service, increase Player’s Health’s security, and measure and improve the use and effectiveness of our Service.  ●	Player’s Health also uses these technologies to serve advertising to you on other sites using third party advertising services. You can control the cookies set in your browser and even block them entirely by configuring your browser settings. If you block cookies, however, you may not be able to use the Services. Additional options for controlling how or whether Player’s Health can target you with advertising is detailed in Section 2 (advertising and marketing) of this policy. By visiting Player’s Health website or using our mobile applications, you consent to the placement of cookies and beacons on your browser, and our use of mobile SDKs in accordance with this Privacy Policy.
      </li>
      <li>
          <strong>Health care information:</strong> Players Health will use information related to your health care in order to provide the Service.
      </li>
  </ul>
  <p>
      <h3>
          2. USES AND DISCLOSURES OF YOUR INFORMATION
      </h3>
  </p>
  <p>
      Player’s Health uses the information it collects about you as set forth below:
  </p>
  <ul>
      <li>
          <strong>To provide the Service:</strong> Player’s Health uses the information it collects to provide the Service to our Users – including to provide User-specific and customized experiences where necessary and appropriate, with the goal of enabling a safe sports environment through our platform.
      </li>
      <li>
          <strong>To improve and customize the Service:</strong> Player’s Health seeks to improve your experience with the Service. As a result, we will analyze data we collect about your use of the Service to inform how we can improve our products, including our software, infrastructure, user interfaces, and integrations with our partners.
      </li>
      <li>
          <strong>To integrate with Third Parties:</strong> To support our Users and ensure safety in sports, Player’s Health may integrate our system with third parties. For example, Player’s Health may integrate the Service to support your preferred sports registration service. If you have an independent direct relationship with a third party integrated into the Service, any permissions you may have granted to that third party will govern their use of your data. Otherwise, any third party used by Player’s Health to help provide the Services will not have any independent right to access or use your personal information.
      </li>
      <li>
          <strong>To communicate with you:</strong> Player’s Health wants to help you know how to best take advantage of the Service, especially as we improve and develop new features. We may send messages relating to your use of the Service – for example, we may send you a note that a new concussion legislation has passed. We may also send you messages about Service-related matters through our dashboard or by email. Also, because we want to improve the Service, we may occasionally send promotional materials out via email so that you are able to take advantage of new or unused features. More details about our advertising and marketing activities are provided below. You can control whether you receive future non-Service-related messages by unsubscribing in the message itself.
      </li>
      <li>
          <strong>To advertise and market to you:</strong> As noted above, Player’s Health relies on cookies, beacons, and mobile SDKs to recognize your browser or mobile device in order to serve you ads. These same technologies are used by Player’s Health to analyze the effectiveness of our advertising and email marketing campaigns. Player’s Health may use third party services, data, and software tools to target advertising to you, perform campaign effectiveness analysis, help us identify and reach potential Users, and track the performance of our site and advertising generally. Player’s Health’s third party advertising vendors do not have any independent right to use Player’s Health’s personal information or that of its Users.
      </li>
      <li>
          <strong>To work with our vendors and service providers:</strong> Player’s Health uses third party vendors and service providers to provide the Service to you. For example, we use Amazon Web Services (AWS) to host the Service in the secure AWS cloud, and, as mentioned above, we also use third parties to help us advertise and market to you. We may also provide some of your information (your email address, for example) to a service provider in order to reach you with an email campaign about new Player’s Health features, however, our service providers and vendors have no independent right to use your data. Similarly, Player’s Health’s instance in AWS is not accessible to any Amazon employee except at our request and to carry out our instructions. In other words, all our vendors are limited to their use of your data in the same way that we are limited in our use of your data by this Privacy Policy.
      </li>
      <li>
          <strong>To comply with valid legal process and similar disclosures:</strong> Player’s Health may disclose your information as permitted by law or as reasonably necessary to:
          <ol type="1">
              <li>
                  Comply with a legal requirement or process, for example, civil and criminal subpoenas, court orders or other compulsory disclosures;
              </li>
              <li>
                  Enforce this Privacy Policy or our Terms of Use Agreement; or,
              </li>
              <li>
                  To protect the rights, property, or safety of Player’s Health, our employees, or our Customers and Users.
              </li>
          </ol>
      </li>
      <li>
          <strong>To disclose to a third party as the result of a sale or change in control of Player’s Health:</strong> Player’s Health may disclose your personal information to a third party as part of a sale of the assets of Player’s Health, or an affiliate, or as a result of a change in control of Player’s Health or an affiliate, or in preparation for any of these events. Any third party to which we transfer control or sell our assets will have the right to continue to use the personal and other information that you provide to us in the manner set out in this Privacy Policy.
      </li>
  </ul>
  <p>
      <h3>
          3. YOUR CHOICES
      </h3>
  </p>
  <ul>
      <li>
          <strong>Opt-Out of Behavioral Advertising and Cookies.</strong> To learn more about advertising technologies and how to opt-out of online behavioral advertising, please visit
          <u><a target="_blank" href="https://www.allaboutcookies.org">https://www.allaboutcookies.org</a></u> or the Network Advertising Initiative’s online resources, at
          <u><a target="_blank" href="https://www.networkadvertising.org">https://www.networkadvertising.org</a></u>, or the Digital Advertising Alliance at
          <u><a target="_blank" href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a></u>. You may also opt out of tracking and receiving online behavioral advertising on your mobile device by some mobile advertising companies and other similar entities by downloading the App Choices app at https://wwwaboutads.com/info/appchoices. These sites are not controlled by Player’s Health, but provide an opt-out from advertising exchanges. Depending on your mobile device, you also can control tracking technologies through your settings by resetting your advertising ID. Please note that the only way to completely “opt out” of the tracking that cookies and other technologies enable is to actively manage the settings on your browser or mobile device, which may negatively impact your ability to use the Service.
      </li>
      <li>
          <strong>Retention:</strong> Player’s Health retains the personal information you provide while your account is in existence or as needed to provide you the Service, including customer support-related reporting and trend analysis. We may retain Customer or User information if retention is reasonably necessary to comply with our legal obligations, meet regulatory requirements, resolve disputes between Customer, Users, or related parties, prevent fraud and abuse, or enforce this Privacy Policy or our Terms of Use Agreement. We may retain personal information, for a limited period of time, if requested by law enforcement.
      </li>
  </ul>
  <p>
      <h3>
          4. IMPORTANT INFORMATION
      </h3>
  </p>
  <ul>
      <li>
          <strong>HIPAA:</strong>  Notwithstanding anything to the contrary, if Player’s Health is providing you with the Service pursuant to a contract with a Covered Entity, Player’s Health will be a Business Associate of that Covered Entity and shall treat all Protected Health Information as required under the terms of its Business Associate Agreement (capitalized terms not previously defined shall have meaning set forth at 45 CFR 160.103).  This Privacy Policy shall not limit the rights or obligations of Player’s Health under the aforementioned Business Associate Agreement.
      </li>
      <li>
          <strong>Security:</strong> Player’s Health has implemented industry standard administrative, technical, and physical security safeguards designed to protect the personal information that you provide. Access to your data on our Service is password-protected, and data is protected by SSL encryption when it is exchanged between your web browser and the Service. We also support secure https access to the Playershealth.com website. To protect any data you store on our servers, we also regularly monitor our system for possible vulnerabilities and attacks.
      </li>
      <li>
          As you know, however, the Internet is not a 100% secure environment, and as a result, we cannot ensure or warrant the security of any information that you transmit to us. There is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our administrative, technical, or physical safeguards. It is your responsibility to protect the security of your login information. Please help keep your account safe by using a strong password.
      </li>
      <li>
          <strong>Storage and Processing:</strong> Your information collected through the Service may be stored and processed in the United States or any other country in which Player’s Health or its subsidiaries, affiliates or service providers maintain facilities. If you are located in the European Union or other regions with laws governing data collection and use that may differ from U.S. law, please note that we may transfer information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction, and you consent to the transfer of information to the U.S. or any other country in which Player’s Health or its affiliates or service providers maintain facilities as well as to the use and disclosure of information about you in connection with the foregoing as described in this Privacy Policy.
      </li>
      <li>
          <strong>Changes to this Privacy Policy:</strong> Player’s Health may change this Privacy Policy from time to time. If we make material changes to the Privacy Policy, we will provide notice to you though the Service or by some other means, such as email. Please review this Privacy Policy frequently, and any notice we provide about material changes carefully. If you agree to the changes, simply continue to use our Service. If you object to any of the changes to our terms and you no longer wish to use our Service, please let us know. Users may not be able to close their accounts without first finding an alternative arrangement to handle their Health records with their sports organization. This Privacy Policy applies to all information that we have about you and your account. Using our Service after a notice of changes has been communicated to you or published on the Service shall constitute consent to the changed terms or practices.
      </li>
      <li>
          <strong>California’s Shine the Light Law:</strong> California Civil Code Section 1798.83, known as the “Shine The Light” law, permits Users who are California residents to request and obtain from us a list of what personal information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year and the names and addresses of those third parties. Requests may be made only once a year and are free of charge. Under Section 1798.83, we currently do not share any personal information with third parties for their own direct marketing purposes.
      </li>
      <li>
          <strong>California’s Do-Not-Track Disclosure:</strong> As required by the California Online Privacy Protection Act (California Business and Professions Code Sections 22575-22579), Player’s Health notes that although we do our best to honor the privacy preferences of our Users, and have implemented industry standard safeguards as described above, we do not respond to Do-Not-Track signals from your browser at this time due to the lack of a neutral and consistent industry standard. Player’s Health does not collect personal information about your online activities over time and across third party websites or services. However, when Player’s Health uses third-party services, data, and software tools to target advertising to you, these third parties may collect personal information about your online activities over time and across third party websites or services.
      </li>
      <li>
          <strong>Users under the age of 13:</strong> The Service is a general audience site not directed at children under the age of 13. Unless listed as a dependent under an adult’s insurance policy or other insurance benefits, Player’s Health does not knowingly collect or solicit any information from anyone under the age of 13 or knowingly allow such persons to register as Users. In the event that we learn that we have collected personal information from a child under the age of 13, we will delete that information as quickly as possible. If you believe that we might have any information from a child under 13 that is not a dependent of one of our Users, please contact us as set out in Section 5 below.
      </li>
  </ul>
  <p>
      <h3>
          5. CONTACT US
      </h3>
  </p>
  <p>
      You may contact us by mail at:
  </p>
  <p>
      Privacy Policy Issues
  </p>
  <p>
      C/O Player’s Health Privacy Officer
  </p>
  <p>
      701 N 3rd St
  </p>
  <p>
      Minneapolis, Mn 55401
  </p>
  <p>
      You may also reach us by email <u><a href="mailto:support@playershealth.com">here</a></u>.
  </p>
`,
};

export const TERMS_AND_CONDITIONS = {
  title: 'Terms and Conditions',
  info: `
  <h1 style="text-align: center;">Player’s Health Terms of Use</h1>
  <p>
      <strong>Effective Date:</strong> December 31, 2020
  </p>
  <p>
      <h3>
          PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE.
      </h3>
  </p>
  <p>
      Welcome to Player’s Health! Player’s Health is an interactive mobile platform designed to enable coaches and parents to quickly and easily document a youth athletes’ injuries and/or medical conditions.
  </p>
  <p>
      This website and the mobile platform are operated by Player’s Health (“we,” “us,” “our,” or “Player’s Health”). The following terms and conditions (together with any documents referred to in them) (collectively, these “TOU”) apply to your use of the website at
      <u><a target="_blank" href="https://www.playershealth.com">https://www.playershealth.com</a></u>
       (the “Site”); the mobile platform; any other websites or applications operated by Player’s Health; and the services and/or content, including, without limitation, any information, text, software, scripts, graphics, photographs, audio and/or visual materials (individually or in combination), features and other materials uploaded, downloaded or appearing on or available through the Site or platform (the “Content”) (collectively, the “System”).
  </p>
  <p>
      This System is limited to users over the age of 18. If you are under the age of 18, you may only use the System with the consent of your parent or legal guardian. By using this System, YOU AFFIRMATIVELY REPRESENT AND WARRANT THAT YOU ARE AT LEAST 18 YEARS OF AGE (AND, IF UNDER 18 YEARS OLD {or the age of majority in the jurisdiction in which you reside}, THAT YOU HAVE PARENTAL PERMISSION TO USE THE SYSTEM) AND THAT YOU POSSESS THE LEGAL RIGHT TO USE THE SYSTEM IN ACCORDANCE WITH THE STATED TERMS OF USE. You hereby agree to supervise usage by, and be responsible for the actions of, any minors who use your computer and/or account(s) to ensure compliance herewith.
  </p>
  <p>
      We take privacy seriously and take all reasonable precautions with both our software and websites to ensure HIPAA compliance. This Terms of Use also incorporates by reference the Player’s Health Privacy Policy.
  </p>
  <p>
      <strong>
          By using this System, you signify your assent to these TOU and our Privacy Policy. If you do not agree to all of the terms and conditions of the TOU or our Privacy Policy, do not use or access the System.
      </strong>
  </p>
  <p>
      Player’s Health may revise and update these TOU at any time. You are responsible for regularly reviewing these TOU and any additional terms posted on other sections of the System. Your continued usage of the System will mean you accept and agree to the modified Terms of Use.
  </p>
  <p>
      <h3>
          THIS SITE DOES NOT PROVIDE MEDICAL ADVICE
      </h3>
  </p>
  <p>
      Player’s Health is not a healthcare provider. Player’s Health does not provide medical advice, diagnosis, or treatment. The contents of the Player’s Health System (“Content”) is for informational purposes only. None of the Content should be construed as medical recommendations, medical advice, or legal advice. The Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified healthcare professional with any questions you have regarding a medical condition or injury. Never disregard professional medical advice. If you believe you may have a medical emergency, call 911 immediately.
  </p>
  <p>
      <h3>
          RELIANCE ON INFORMATION POSTED
      </h3>
  </p>
  <p>
      The information presented on or through the System is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the System, or by anyone who may be informed of any of its contents.
  </p>
  <p>
      <h3>
          PERSONAL NON-COMMERCIAL USE LIMITATION
      </h3>
  </p>
  <p>
      Unless otherwise specified, the System is for your personal and non-commercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any Content, information, software, products or services obtained from the System.
  </p>
  <p>
      <h3>
          NO UNLAWFUL OR PROHIBITED USE
      </h3>
  </p>
  <p>
      As an express condition of your use of the System, you agree, represent and warrant that you will not use the System for any purpose that is unlawful or prohibited by these TOU or any terms, conditions, and notices contained herein. You may not use the System in any manner that could damage, disable, overburden, or impair the System or the network(s) connected to the System or interfere with any other party's use and enjoyment of the System. You may not attempt to gain unauthorized access to the System, other accounts, computer systems or networks connected to the System, through hacking, password mining or any other means. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the System.
  </p>
  <p>
      <h3>
          TERMINATION OF ACCESS
      </h3>
  </p>
  <p>
      Player’s Health reserves the right, in its sole discretion, to terminate or limit your access to any or all of the System or any portion thereof at any time, without notice.
  </p>
  <p>
      <h3>
          MEMBER ACCOUNT, PASSWORD, AND SECURITY
      </h3>
  </p>
  <p>
      Participants will receive a password from Player’s Health to access certain information on the System. You are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to notify Player’s Health immediately of any unauthorized use of your account or any other breach of security. Player’s Health will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by Player’s Health or another party due to someone else using your account or password. You may not use anyone else's account at any time without the permission of the account holder and Player’s Health.
  </p>
  <p>
      <h3>
          DISCLAIMER OF WARRANTIES AND LIMITATION ON LIABILITY
      </h3>
  </p>
  <p>
      The use of the System and the Content is at your own risk.
  </p>
  <p>
      When using the System, information will be transmitted over a medium that may be beyond the control and jurisdiction of Player’s Health. Accordingly, Player’s Health assumes no liability for or relating to the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Site.
  </p>
  <p>
      The System and the Content are provided on an "as is" basis. PLAYER’S HEALTH, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, PLAYER’S HEALTH MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, CURRENTNESS, OR TIMELINESS OF THE CONTENT, SOFTWARE, TEXT, GRAPHICS, LINKS, OR COMMUNICATIONS PROVIDED ON OR THROUGH THE USE OF THE SYSTEM OR CONTENT.
  </p>
  <p>
      IN NO EVENT SHALL PLAYER’S HEALTH, OR ANY THIRD PARTIES MENTIONED ON THE SYSTEM BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, PERSONAL INJURY/WRONGFUL DEATH, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OF OR INABILITY TO USE THE SYSTEM OR THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT PLAYER’S HEALTH, OR ANY THIRD PARTIES MENTIONED ON THE SITE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY EVENT, PLAYER’S HEALTH, OR ANY THIRD PARTIES, LIABILITY WILL NOT EXCEED THE AMOUNT PAID BY YOU TO PLAYER’S HEALTH TO ACCESS THE SYSTEM IN THE TWELVE (12) MONTHS PRIOR TO THE ACCRUAL OF A CLAIM BY YOU AGAINST PLAYER’S HEALTH (OR, IF A TEAM OR PLAYER, NO GREATER THAN $100.000). IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SYSTEM, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SYSTEM. PLAYER’S HEALTH, OR ANY THIRD PARTY, IS NOT LIABLE FOR ANY PERSONAL INJURY, INCLUDING DEATH, CAUSED BY YOUR USE OR MISUSE OF THE SYSTEM OR CONTENT. ANY CLAIMS ARISING IN CONNECTION WITH YOUR USE OF THE SYSTEM OR CONTENT MUST BE BROUGHT WITHIN ONE (1) YEAR OF THE DATE OF THE EVENT GIVING RISE TO SUCH ACTION OCCURRED. REMEDIES UNDER THESE TOU ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY PROVIDED FOR IN THE TOU.
  </p>
  <p>
      <h3>
          MATERIAL PROVIDED TO PLAYER’S HEALTH
      </h3>
  </p>
  <p>
      If you submit any materials to the System or to Player’s Health, you are granting Player’s Health, its affiliated companies and its designees and sub licensees permission to use such information in connection with the operation of their businesses including, without limitation, the right to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat the same SOLELY on your closed Network; and to publish your name in connection with your Network. No compensation will be paid with respect to submitted materials. Player’s Health is under no obligation to post or use any materials you may provide and Player’s Health may remove any materials at any time in its reasonable discretion.
  </p>
  <p>
      UNDER NO CIRCUMSTANCES SHALL PLAYER’S HEALTH OR ITS AFFILIATES BE LIABLE IN ANY WAY FOR ANY MATERIALS SUBMITTED TO BY USERS.
  </p>
  <p>
      <h3>
          ELECTRONIC COMMUNICATIONS
      </h3>
  </p>
  <p>
      Visiting or using the System or sending emails to us constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures, and other communications that we provide to you electronically, via email, and on the System, satisfy any legal requirement that such communications be in writing. To opt out of receiving marketing emails from us, follow the “unsubscribe” link on the email to change your user preferences. There are certain system-generated and legal notices that you cannot opt out of without deactivating your account.
  </p>
  <p>
      <h3>
          INDEMNIFICATION
      </h3>
  </p>
  <p>
      You agree to indemnify and hold Player’s Health, its affiliates, and their respective officers, directors, employees, agents, successors and assigns harmless from any claim, demand, or damage, including reasonable attorneys' fees, asserted by any third party due to or arising out of your use of the System and/or any information (text or graphical), software, or products obtained through the System and/or from Player’s Health. We reserve the right, at our own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with us in asserting any available defenses.
  </p>
  <p>
      <h3>
          APPLICABLE LAW/DISPUTE RESOLUTION
      </h3>
  </p>
  <p>
      You expressly agree that all disputes against Player’s Health, including but not limited to disputes as to these TOU; the System’s licenses; the System’s access restrictions and/or termination; Player’s Health’s trademarks, copyrights, patents, trade secrets, trade dress and other intellectual property rights and the intellectual property rights of you and/or third parties; member accounts; privacy; and disclaimer of warranties shall be governed by the internal laws of the State of Delaware, U.S.A. without regard to choice of law principles and you and Player’s Health both expressly agree that any and all disputes that may arise out of these TOU or out of each Party’s relationship with the other shall be resolved in a court of competent jurisdiction located within Cook County, Illinois. You hereby irrevocably consent to exclusive jurisdiction (and to be personally subject to said jurisdiction) and venue in such courts.
  </p>
  <p>
      <h3>
          Links to Other Websites and Connecting through Social Media
      </h3>
  </p>
  <p>
      The System may contain hyperlinks to websites operated by third parties. We do not control such websites and will not be responsible for their content, or for any breach of contract, or any intentional or negligent action on the part of such third parties, which results in any loss, damage, delay, or injury to you or your companions. Inclusion of any linked website on the System does not imply or constitute approval or endorsement of the linked website by us. If you decide to leave the System to access these third-party sites, you do so at your own risk. All rules, policies (including privacy policies), and operating procedures of websites operated by third parties will apply to you while on such sites. We are not responsible for information provided by you to third parties.
  </p>
  <p>
      <h3>
          GEOGRAPHIC RESTRICTIONS
      </h3>
  </p>
  <p>
      Player’s Health is based in the United States. We provide this System for use only by persons located in the United States. We make no claims that the System or any of its content is accessible or appropriate outside of the United States. Access to the System may not be legal by certain persons or in certain countries. If you access the System from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
  </p>
  <p>
      <h3>
          WAIVER AND SEVERABILITY
      </h3>
  </p>
  <p>
      No waiver of any of the terms of these TOU will be valid unless in writing and designated as such. No failure or delay in enforcing a party's rights shall be construed as a waiver.
  </p>
  <p>
      If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.
  </p>
  <p>
      <h3>
          COPYRIGHTS AND TRADEMARKS
      </h3>
  </p>
  <p>
      All of the materials and the Content appearing on the System are either owned by Player’s Health or licensed or sub-licensed (including, without limitation, through these TOU) to Player’s Health and are used by Player’s Health in accordance with the applicable licenses and with applicable law. You may not copy, distribute or otherwise disseminate any materials or Content (including any logos, images, or data), from the System unless you are the owner of said materials or content or have express written permission from the owner of said materials or content to do so.
  </p>
  <p>
      <h3>
          LANGUAGE
      </h3>
  </p>
  <p>
      It is the express will of the parties that this agreement and all related documents have been drawn up in English. In the event these TOU are translated into another language or otherwise reprinted, this (and future amended) English language version of the TOU shall govern as to any inconsistencies.
  </p>
  <p>
      <h3>
          ENTIRE AGREEMENT
      </h3>
  </p>
  <p>
      Unless otherwise specified herein or in a writing executed by both parties, these TOU constitute the entire agreement between you (the user) and Player’s Health with respect to the use of the System and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and Player’s Health with respect to the System.
  </p>
  <p>
      <h3>
          ERRORS, OMISSIONS, AND CHANGES
      </h3>
  </p>
  <p>
      While Player’s Health makes every effort to ensure the accuracy of materials presented, you, the user is warned that this service could include typographical errors and/or technical inaccuracies. Player’s Health reserves the right to make improvements and/or changes to these TOU and/or to the products and services described on the System at any time.
  </p>
  <p>
      <h3>
          CUSTOMER SERVICE
      </h3>
  </p>
  <p>
      If you have any questions, comments, or concerns, please contact our customer service department, Monday through Friday by contacting us or by telephone at <u><a href="tel:312-888-6497">312-888-6497</a></u> or by email at <u><a href="mailto:support@playershealth.com">support@playershealth.com</a></u>.
  </p>`,
};

export const FRAUD_STATEMENTS = {
  title: 'Fraud Statements',
  info: `
    <p>FOR RESIDENTS OF ALL STATES OTHER THAN THOSE LISTED BELOW:</p>
    <p>
    Claim submission to A-G Administrators, administering this policy on behalf of National Union Fire Insurance Company of Pittsburgh, PA.
    Need the following fraud language & Affidavit:
    </p>
    <p>
      AFFIDAVIT: I verify that the statement on other insurance is accurate and complete. I understand that the intentional furnishing of incorrect information via this portal may be fraudulent and violate federal laws as well as state laws. I agree that if it is determined at a later date that there are other insurance benefits collectible on this claim I will reimburse A-G Administrators to the extent for which A-G Administrators would not have been liable.
    </p>
    <p>
      AUTHORIZATION TO RELEASE INFORMATION: I authorize any Health Care Provider, Doctor, Medical Professional, Medical Facility, Insurance Company, Person or Organization to release any information regarding medical, dental, mental, alcohol or drug abuse history, treatment or benefits payable, including disability or employment related information concerning the patient, to A-G Administrators and its designees.
    </p>
    <p>
      PAYMENT AUTHORIZATION: I authorize all current and future medical benefits, for services rendered and billed as a result of this claim, to be made payable to the physicians and providers indicated on the invoices.
    </p>
    <p>
      FRAUD WARNING: Any person who, knowingly and with intent to defraud, or helps commit a fraud against, any insurance company or other person:(1) files an application for insurance or statement of claim containing any materially false information; or (2) conceals for the purpose of misleading, information concerning any material fact thereto, commits or may be committing a fraudulent insurance act, which is a crime and subjects such person to criminal and/or civil penalties. For residents of the following states, please see below: California, Colorado, District of Columbia, Florida, New York, Tennessee, Texas or Virginia. The laws of some states require us to furnish you with the following notices. Please see below:
    </p>
    <p>
      WARNING. Any person who knowingly:
    </p>
    <p>
      Alabama: presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution fines or confinement in prison, or any combination thereof.
    </p>
    <p>
      Alaska: and with intent to injure, defraud, or deceive an insurance company files a claim containing false, incomplete, or misleading information may be prosecuted under state law.
      Arizona, Arkansas and Rhode Island: presents a false or fraudulent claim for payment of a loss or benefit is subject to criminal and civil penalties, or specific to AR and RI: or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
    </p>
    <p>
      California: For your protection California law requires the following to appear on this form: Any person who knowingly presents false or fraudulent claim for payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.
    </p>
    <p>
      Delaware: and with intent to injure, defraud or deceive any insurer, files a statement of claim containing any false, incomplete or misleading information is guilty of a felony.
    </p>
    <p>Florida: and with intent to injure, defraud, or deceive any insurer, files a statement of claim containing any false, incomplete, or misleading information is guilty of a felony of the third degree.</p>
    <p>
      Idaho and Indiana: and with intent to defraud or deceive any insurance company, files a statement of claim containing any false, incomplete, or misleading information (for Idaho) is guilty of and (for Indiana) commits a felony.
    </p>
    <p>
      Kentucky: and with intent to defraud any insurance company or other person files a statement of claim containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime.
    </p>
    <p>
      New York: and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation.
    </p>
    <p>
      Pennsylvania: and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.
    </p>
    <p>
      Louisiana: knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
    </p>
    <p>
      New Mexico: presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and criminal penalties.
    </p>
    <p>
      Texas: presents a false or fraudulent claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.
    </p>
    <p>
      West Virginia: presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
    </p>
    <p>
      Maryland: or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly and willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
    </p>
    <p>
      New Jersey: files a statement of claim containing any false or misleading information is subject to criminal and civil penalties.
    </p>
    <p>
      Ohio: with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.
    </p>
    <p>
      Oklahoma: and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.
    </p>
    <p>
      Oregon: and with intent to defraud any insurance company or other person files an application for insurance or a statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material hereto, may be subject to prosecution for insurance fraud.
    </p>
    <p>
      Puerto Rico: and with the intention of defrauding presents false information in an insurance application, or presents, helps, or causes the presentation of a fraudulent claim for the payment of a loss or any other benefit, or presents more than one claim for the same damage or loss, shall incur a felony and, upon conviction, shall be sanctioned for each violation with the penalty of a fine of not less than five thousand (5,000) dollars and not more than ten thousand (10,000) dollars, or a fixed term of imprisonment for three (3) years, or both penalties. If aggravating circumstances are present, the penalty thus established may be increased to a maximum of five (5) years; if extenuating circumstances are present, it may be reduced to a minimum of two (2) years.
    </p>
    <p>
      WARNING: Colorado: It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable for insurance proceeds shall be reported to the Colorado Division of Insurance within the Department of Regulatory Agencies.
    </p>
    <p>
      District of Columbia: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.
    </p>
    <p>
      Hawaii: Presenting a fraudulent claim for payment of a loss or benefit is a crime punishable by fines or imprisonment, or both.
      Maine, Tennessee, Virginia and Washington: It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits.
      Minnesota: A person who files a claim with intent to defraud or helps commit a fraud against an insurer is guilty of a crime.
    </p>
    <p>
      New Hampshire: Any person who, with a purpose to injure, defraud or deceive any insurance company, files a statement of claim containing any false, incomplete or misleading information is subject to prosecution and punishment for insurance fraud, as provided in RSA 638.20.
    </p>
  `,
};
