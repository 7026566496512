<template>
  <PhDialog
    v-model="shown"
    @close="handleDialogClose"
    :heading="title"
    contentTextAlign="left"
    :fullscreen="true"
  >
    <template v-slot:content>
      <p v-html="description"></p>
    </template>
    <template v-slot:footer>
      <v-layout justify-end :styles="styles">
        <v-btn @click="handleAgreeClick">Ok</v-btn>
      </v-layout>
    </template>
  </PhDialog>
</template>

<script>
import { dialogWrapper } from '@/mixins';
import { FRAUD_STATEMENTS } from '@/utils/content';

export default {
  name: 'ClaimsTermsDialog',
  mixins: [dialogWrapper],
  props: {
    styles: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: FRAUD_STATEMENTS.title,
    },
    description: {
      type: String,
      default: FRAUD_STATEMENTS.info,
    },
  },
  methods: {
    handleAgreeClick() {
      this.$emit('agree');
    },
  },
};
</script>
